import {useAssignmentStore} from '~/stores/assignment';
import type {Assignment} from '~/types';

export default defineNuxtRouteMiddleware(async to => {
  const {isAuth} = useAuthorizationStore();

  if (!isAuth) return;

  const id = to.params.id as string;

  const {fetchAssignment, setCurrentAssignment} = useAssignmentStore();

  const {data, status} = await fetchAssignment(id);

  if (status.value === 'success') {
    setCurrentAssignment(data.value! as Assignment);
  }
});
